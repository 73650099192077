import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../../../supabaseClient';
import NavBar from '../../NavBar';

const BenefitDetail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const benefitId = queryParams.get('benefitId');

  const [benefitDetails, setBenefitDetails] = useState([]);
  const [benefitImages, setBenefitImages] = useState([]);

  useEffect(() => {
    const fetchBenefitDetails = async () => {
      try {
        const { data: benefitData, error: benefitError } = await supabase
          .from('benefit')
          .select('name, description')
          .eq('id', benefitId)
          .single();

        if (benefitError) throw benefitError;

        const { data: imageData, error: imageError } = await supabase
          .from('imagebenefit')
          .select('image_id')
          .eq('benefit_id', benefitId);

        if (imageError) throw imageError;

        const imageIds = imageData.map(ib => ib.image_id);

        const { data: imagesData, error: imagesError } = await supabase
          .from('image')
          .select('base64image')
          .in('id', imageIds);

        if (imagesError) throw imagesError;

        setBenefitDetails(benefitData);
        setBenefitImages(imagesData.map(img => img.base64image));
      } catch (error) {
        console.error('Error fetching benefit details or images:', error);
      }
    };

    fetchBenefitDetails();
  }, [benefitId]);

  return (
    <div className="min-h-screen bg-pearl">
      {/* NavBar */}
      <NavBar sections={["Beneficios", "Actividades", "Hospedajes", "Conectarse", "Chats"]} />

      <div className="container mx-auto mt-16 px-4 flex justify-center">
        {/* Detalles del beneficio */}
        <div className="bg-grey w-[1000px] h-[600px] rounded-3xl p-12 shadow-lg max-w-2xl mx-auto text-center text-white">
          <h1 className="text-4xl font-extrabold mb-8">{benefitDetails.name}</h1>

          {/* Imagen centrada */}
          {benefitImages.length > 0 && (
            <img
              src={benefitImages[0]}
              alt={benefitDetails.name}
              className="w-full h-72 object-cover rounded-lg mb-8"
            />
          )}

          <p className="text-2xl leading-relaxed">{benefitDetails.description}</p>
        </div>
      </div>
    </div>
  );
};

export default BenefitDetail;

