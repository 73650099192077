async function handleAutoLogin() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/validate_ongoing_login`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json", // El body se enviará en formato JSON
        },
      }
    );

    const isAvailable = await response.json();
    if (response.ok && isAvailable.isAvailable) {
      console.log("Usuario logeado con éxito: ", isAvailable);
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error en la solicitud:", error);
    return false;
  }
}

export default handleAutoLogin;
