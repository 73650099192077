import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CategoryButton from '../../CategoryButton';
import BenefitSearch from '../../BenefitSearch';
import NavBar from '../../NavBar';
import Carousel from '../../Carousel';
import BenefitCard from '../../BenefitCard';
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import BenefitFloatingButton from '../../BenefitFloatingButton';

function HomePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null); // Estado para saber qué botón está subrayado
  const universityId = queryParams.get('university');
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [benefits, setBenefits] = useState([]);
  
  const navigate = useNavigate(); //inicializa useNavigate

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/categories`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Error fetching categories');
        }

        const categories = await response.json();
        setCategories(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [universityId]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/featured_images`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching categories");
        }

        const featured_images = await response.json();

        setImages(featured_images);
      } catch (error) {
        console.error("Error fetching featured images:", error);
      }
    };

    fetchImages();
  }, [universityId]);

 
  useEffect(() => {
    const fetchBenefitsByCategory = async () => {
      if (!selectedCategory) return; // Si no hay categoría seleccionada, no hacer nada

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/${selectedCategory}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching benefits");
        }

        const benefits = await response.json();

        // Actualizar el estado con los beneficios filtrados
        setBenefits((prevBenefits) => ({
          ...prevBenefits,
          [selectedCategory]: benefits,
        }));
      } catch (error) {
        console.error("Error fetching benefits:", error);
      }
    };

    fetchBenefitsByCategory();
  }, [selectedCategory, universityId]);

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName); // Actualiza la categoría activa
    setSelectedCategory(categoryName); // Actualiza la categoría seleccionada para filtrar los beneficios
  };
  
  const handleCardClick = (benefitId, category) => {
    navigate(`/benefit/${benefitId}?benefitId=${benefitId}`); // Redirige con benefitId y category
  };
  
  return (
    <div>
      <div className="relative">
        {/* NavBar */}
        <div>
          <NavBar sections={["Beneficios", "Actividades", "Hospedajes", "Conectarse", "Chats"]} />
        </div>
  
        {/* Benefit Search positioned above the carousel */}
      <div className="absolute top-15 left-1/2 transform -translate-x-1/2 z-20">
        <BenefitSearch />
      </div>

        {/* Carousel */}
        <div>
          <Carousel images={images} />
        </div>
  
        {/* Category Buttons */}
        <div className="absolute top-1/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center space-x-[43px] z-20">
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <CategoryButton
                key={index}
                categoryName={category.name}
                icon={category.base64image}
                isActive={activeCategory === category.name} // Compara si esta categoría está activa
                onClick={() => handleCategoryClick(category.name)} // Asigna la función para manejar clics
              />
            ))
          ) : (
            <p className="col-span-3 text-center">No categories available</p>
          )}
        </div>
      </div>
  
      {/* Benefit Cards */}
      <div className="relative mt-40 flex flex-wrap justify-center space-x-4 z-10">
        {selectedCategory && benefits[selectedCategory] && 
          benefits[selectedCategory].map((benefit, index) => (
            <BenefitCard
              key={index}
              imageUrl={benefit.images[0]}
              alt={benefit.name}
              title={benefit.name}
              onClick={() => handleCardClick(benefit.id, selectedCategory)}
            />
          ))
        }
      </div>

      <BenefitFloatingButton/>
      
    </div>
  );
  
}

export default HomePage;
