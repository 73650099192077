const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null; // Return null if the cookie is not found
};

async function handleHome(navigate) {
  const token = getCookie("token");

  if (token === null) return false;

  const body = { token };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/universities/my_university`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // El body se enviará en formato JSON
        },
        body: JSON.stringify(body), // Convertimos el objeto JS en una cadena JSON
      }
    );

    const univerity_id = await response.json();
    if (!response.ok) {
      console.error("Error al registrar usuario:", univerity_id.message);
      return;
    }
    navigate(`/Home/?university=${univerity_id}`);
  } catch (error) {
    console.error("Error en la solicitud:", error);
  }
}

export default handleHome;
