import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import { useLocation } from "react-router-dom";

function BenefitSearch() {
  const [query, setQuery] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [matchingBenefits, setMatchingBenefits] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const universityId = queryParams.get("university");

  // Llamada a la API para obtener los beneficios
  useEffect(() => {
    console.log(`${process.env.REACT_APP_API_URL}/benefits/${universityId}`);
    const fetchBenefits = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/benefit`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching benefits: ${response.statusText}`);
        }

        const data = await response.json();
        setBenefits(data); // Aquí cargamos los beneficios en el estado
      } catch (error) {
        console.error(error);
        setErrorMessage("Error al obtener los beneficios.");
      }
    };

    if (universityId) {
      fetchBenefits();
    }
  }, [universityId]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    if (inputValue.trim() === "") {
      setMatchingBenefits([]);
      setErrorMessage(null);
      return;
    }

    const filteredSuggestions = benefits.filter((benefit) =>
      benefit.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    setMatchingBenefits(filteredSuggestions);

    if (filteredSuggestions.length === 0) {
      setErrorMessage(
        `No se encontraron beneficios que coincidan con "${inputValue}"`
      );
    } else {
      setErrorMessage(null);
    }
  };

  const handleSuggestionClick = (benefitName) => {
    setQuery(benefitName);
    setMatchingBenefits([]);
  };

  const handleSearchSubmitWrapper = (e) => {
    e.preventDefault();

    if (query.trim() === "") {
      setErrorMessage("Debes ingresar algo para buscar");
      return;
    }

    const filteredBenefits =
      matchingBenefits.length > 0
        ? matchingBenefits
        : benefits.filter((benefit) =>
            benefit.name.toLowerCase().includes(query.toLowerCase())
          );

    if (filteredBenefits.length > 0) {
      navigate(
        `/benefit/${filteredBenefits[0].id}?benefitId=${filteredBenefits[0].id}`
      );
    } else {
      setErrorMessage(
        `No se encontraron beneficios que coincidan con "${query}"`
      );
    }
  };

  return (
    <SearchBar
      query={query}
      handleInputChange={handleInputChange}
      handleSearchSubmitWrapper={handleSearchSubmitWrapper}
      matchingBenefits={matchingBenefits}
      handleSuggestionClick={handleSuggestionClick}
      errorMessage={errorMessage}
    />
  );
}

export default BenefitSearch;
