import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/Pages/LandingPage/LandingPage";
import HomePage from "./components/Pages/HomePage/HomePage";
import Contact from "./components/Pages/Support/Support";
import RegisterPage from "./components/Pages/Register/Register";
import Login from "./components/Pages/Login/Login";
import BenefitDetail from "./components/Pages/BenefitDetail/BenefitDetail";
import Verification from "./components/Pages/Verification/Verification";
import Verified from "./components/Pages/Verified/Verified";

const App = () => {
  return (
    <div className="bg-pearl min-h-screen text-roboto">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Home" element={<HomePage />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Register" element={<RegisterPage />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/benefit/:benefitId" element={<BenefitDetail />} />
          <Route path="/Verification" element={<Verification />} />
          <Route path="/Verified/:token" element={<Verified />} />
      </Routes>
      </Router>
    </div>
  );
};

export default App;
