// BenefitFloatingButton.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useLocation } from "react-router-dom";
import FloatingButton from './FloatingButton';

function BenefitFloatingButton() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const universityId = queryParams.get("university");
    const [showForm, setShowForm] = useState(false);
    const [categoryOption, setCategoryOption] = useState('existing');
    const [showNewCategoryForm, setShowNewCategoryForm] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [category, setCategory] = useState('');
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryIcon, setNewCategoryIcon] = useState(null);
    const [categoryError, setCategoryError] = useState('');

    const handleButtonClick = () => setShowForm(true);
    const handleFormClose = () => {
        setShowForm(false);
        setCategoryOption('existing');
        setShowNewCategoryForm(false);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setCategoryError('');
        if (categoryOption === 'existing' && !category) {
            alert('Por favor, selecciona una categoría válida antes de continuar.');
            return;
        }
        try {
            let category_id;
            if (categoryOption === 'existing') {
                const { data: categoryData, error: categoryError } = await supabase
                    .from('category')
                    .select('id')
                    .eq('name', category)
                    .single();
                if (categoryError || !categoryData) {
                    setCategoryError(`La categoría "${category}" no existe.Ingrese una válida!`);
                    return;
                }
                category_id = categoryData.id;
            }
            const { data: imageData, error: imageError } = await supabase
                .from('image')
                .insert([{ base64image: image, name }])
                .select();
                
                
            if (imageError) {
                console.error("Error uploading image:", imageError);
            } else {
                console.log("Image uploaded successfully:", imageData);
            }

            if (imageError) throw imageError;
    
            const image_id = imageData[0].id;

            // Insertamos el beneficio en la tabla benefit
            const { data: benefitData, error: benefitError } = await supabase
                .from('benefit')
                .insert([{ description, category_id, id_suplier: universityId, name }])
                .select();
            if (benefitError) throw benefitError;

            const benefit_id = benefitData[0].id;

             // Insertamos la relación en la tabla imagebenefit
            const { error: imageBenefitError } = await supabase
                .from('imagebenefit')
                .insert([{ benefit_id, image_id }]);
            if (imageBenefitError) throw imageBenefitError;

            //Insertamos la relacion en la tabla universitybenefit
            const { error: universitybenefitError } = await supabase
                .from('universitybenefit')
                .insert([{ benefit_id, university_id: universityId }]);
            if (universitybenefitError) throw universitybenefitError;

            // Aquí puedes insertar en universitybenefit si tienes los datos
            alert('Beneficio agregado exitosamente!');
            handleFormClose();
        } catch (error) {
            console.error('Error al agregar el beneficio:', error.message);
        }
    };
    
    // Función para manejar la subida de una nueva categoría
    const handleNewCategorySubmit = async (event) => {
        event.preventDefault();
        if (!newCategoryName || !newCategoryIcon) {
            alert('Por favor, completa todos los campos para agregar una nueva categoría.');
            return;
        }
        try {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Icon = reader.result;

                // Insertar el ícono en la tabla de imágenes y obtener el id del ícono
                const { data: iconData, error: iconError } = await supabase
                    .from('image')
                    .insert([{ base64image: base64Icon, name: `${newCategoryName}_icon` }])
                    .select();
                if (iconError) {
                    console.error("Error al subir el ícono:", iconError);
                    alert('Hubo un problema al subir el ícono. Inténtalo nuevamente.');
                    return;
                }
                const icon_id = iconData[0].id;

                // Insertar la nueva categoría en la base de datos referenciando el id del ícono
                const { data: newCategoryData, error: newCategoryError } = await supabase
                    .from('category')
                    .insert([{ name: newCategoryName, icon: icon_id }])
                    .select();
                if (newCategoryError) {
                    console.error("Error al agregar la nueva categoría:", newCategoryError);
                    alert('Hubo un problema al agregar la nueva categoría. Inténtalo nuevamente.');
                } else {
                    console.log("Categoría agregada exitosamente:", newCategoryData);
                    alert('Categoría agregada exitosamente.');

                    // Limpiar los campos del formulario de nueva categoría y cerrar el formulario
                    setNewCategoryName('');
                    setNewCategoryIcon(null);
                    setShowNewCategoryForm(false);
                    setCategoryOption('existing');
                }
            };
            reader.readAsDataURL(newCategoryIcon);
        } catch (error) {
            console.error('Error al manejar la nueva categoría:', error.message);
        }
    };

    const handleCategoryChange = (option) => {
        setCategoryOption(option);
        setShowNewCategoryForm(option === 'new');
        setCategoryError('');// Limpiar el error al cambiar la opción de categoría
    };

    const handleCategoryInputChange = (e) => {
        setCategory(e.target.value);
        setCategoryError(''); // Limpiar el error en cada cambio de entrada
    };

    return (
        <FloatingButton
            showForm={showForm}
            handleButtonClick={handleButtonClick}
            handleFormClose={handleFormClose}
            handleSubmit={handleSubmit}
            handleImageUpload={handleImageUpload}
            handleCategoryChange={handleCategoryChange}
            categoryOption={categoryOption}
            showNewCategoryForm={showNewCategoryForm}
            categoryError={categoryError}
            handleCategoryInputChange={handleCategoryInputChange}
            setName={setName}
            setDescription={setDescription}
            setNewCategoryName={setNewCategoryName}
            setNewCategoryIcon={setNewCategoryIcon}
            handleNewCategorySubmit={handleNewCategorySubmit}
        />
    );
}

export default BenefitFloatingButton;
