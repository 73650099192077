import { CCarousel, CCarouselItem, CImage } from "@coreui/react";

export default function Carousel({ images }) {
  return (
    <div>
    <CCarousel controls transition="crossfade" interval={5000} className="h-[40vh]">
      {images.map((image, index) => (
        <CCarouselItem key={index}>
          <CImage
            className="d-block w-full h-[40vh] object-cover"  // Usa object-cover para ajustar al ancho sin distorsionar
            src={image}
            alt={`slide ${index + 1}`}
          />
        </CCarouselItem>
      ))}
    </CCarousel>
    </div>
  );
}
